<template>
  <div class="risk-point-add-wrapper">
    <van-nav-bar
      fixed
      :title="!pageId ? '新增故障' : !editable ? '修改故障' : '故障详情'"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
      <template #right>
        <span
          v-if="btnAuth.update && pageId && editable && faultStatus === 0"
          class="right-btn"
          @click="editable = false"
          >编辑</span
        >
        <van-icon
          v-if="pageId && faultStatus !== 0"
          class-prefix="iconfont"
          class="right-btn-icon"
          name="gzl3"
          @click="goFlowPage"
        />
      </template>
    </van-nav-bar>
    <div class="form-list">
      <van-form ref="vanForm" :show-error-message="false">
        <template v-if="faultStatus === 1">
          <van-field
            v-if="pageId"
            required
            label="审批："
            :placeholder="'请选择审批'"
            :rules="[
              {
                required: true,
                message: '请选择审批',
                trigger: 'onChange'
              }
            ]"
            name="status"
            :label-width="100"
            input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="form.approveStatus"
                direction="horizontal"
              >
                <van-radio
                  v-for="item in passStatusData"
                  :key="item.value"
                  :name="item.value"
                  >{{ item.label }}
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-if="pageId"
            v-model="form.content"
            :required="!form.status"
            :rules="[
              {
                required: !form.status,
                message: '请输入意见',
                trigger: 'onChange'
              }
            ]"
            :readonly="!editable"
            :label-width="100"
            :placeholder="'请输入意见'"
            input-align="right"
            rows="2"
            autosize
            type="textarea"
            label="意见"
            maxlength="500"
            show-word-limit
          >
          </van-field>
        </template>
        <van-field
          v-model="form.equipmentRecordName"
          :label-width="100"
          :required="!editable"
          :is-link="!editable"
          :rules="[
            {
              required: !editable,
              message: '请添加关联设备',
              trigger: 'onChange'
            }
          ]"
          label="关联设备："
          name="poiName"
          input-align="right"
          :placeholder="!editable ? '请添加关联设备' : '--'"
          readonly
          @click="checkEquipment"
        />
        <van-field
          v-model="form.description"
          :required="!editable"
          :readonly="editable"
          :label-width="100"
          :rules="[
            {
              required: !editable,
              message: '请填写故障描述',
              trigger: 'onChange'
            }
          ]"
          :placeholder="editable ? '--' : '请填写故障描述'"
          input-align="right"
          rows="2"
          autosize
          type="textarea"
          label="故障描述："
          maxlength="500"
          :show-word-limit="!editable"
        >
        </van-field>
        <!-- 组件 -->
        <select-popup
          :choose-value="popupValue"
          :data="popupData"
          title="选择设备"
          :visible="popupVisible"
          :popup-style="{
            height: '70vh'
          }"
          value-key="id"
          label-key="name"
          :show-filter="true"
          @close="onPopupClose"
          @confirm="onPopupConfirm"
          @reset="onPopupReset"
        />

        <van-field
          v-model="form.typeName"
          class="hide-error"
          readonly
          :required="!editable"
          :rules="[
            {
              required: !editable,
              message: '故障类型必填',
              trigger: 'onChange'
            }
          ]"
          :label-width="100"
          input-align="right"
          label="故障类型："
          :placeholder="editable ? '--' : '故障类型'"
          @click="select(1)"
        ></van-field>
        <van-field
          v-if="$route.query.no"
          v-model="$route.query.no"
          input-align="right"
          :label-width="100"
          label="关联工单："
          name="no"
          placeholder="请输入内容"
          readonly
        />
        <van-field
          v-if="pageId && editable"
          v-model="form.cuserName"
          input-align="right"
          :label-width="100"
          label="创建人："
          name="cuserName"
          placeholder="请输入内容"
          readonly
        />
        <van-field
          v-if="pageId && editable"
          v-model="form.ctime"
          input-align="right"
          :label-width="100"
          label="创建时间："
          name="ctime"
          placeholder="请输入内容"
          readonly
        />
        <SelectPopupGrade
          v-model="popupVisibleObj[1].value"
          :immediately="false"
          list-key="value"
          :title="popupVisibleObj[1].title"
          :list.sync="popupVisibleObj[1].list"
          :visible="popupVisibleObj[1].visiable"
          @change="v => popupGradeChange(v, 0, 1)"
          @close="popupVisibleObj[1].visiable = false"
        />
      </van-form>
    </div>
    <div
      class="footer-box"
      :class="{ 'is-add': isAdd, 'is-approve': form.status === 1 }"
    >
      <!-- 新增状态，保存、提交 -->
      <template v-if="isAdd">
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          @click="pageSave()"
          >保 存
        </van-button>
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          @click="pageSubmit()"
          >提 交
        </van-button>
      </template>
      <template v-if="pageId">
        <!-- 待提交状态，保存、提交-->
        <template v-if="!editable && form.status === 0">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="default"
            @click="cancel()"
            >取消
          </van-button>
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="pageSave()"
            >保 存
          </van-button>
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="pageSubmit()"
            >提 交
          </van-button>
        </template>
        <template v-if="form.status === 1">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="pageSignatureSubmit()"
            >提 交</van-button
          >
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import {
  getDictDeviceFaultType,
  getDeviceFaultInfo,
  saveDeviceFault,
  updateSaveDeviceFault,
  submitDeviceFault,
  updateSubmitDeviceFault,
  confirmDeviceFault,
  rejectDeviceFault
} from "../../../api/psm/device";
import { getLinkDeviceSltData } from "../../../api/basics/devices";
import { operateDialog } from "@/utils";

export default {
  name: "DeviceFaultDetail",
  data() {
    return {
      pageId: this.$route.params.id,
      editable: !!this.$route.params.id,
      approveEditable: false,
      form: {
        description: "", // 备注
        type: "",
        typeName: "",
        cuserName: "",
        equipmentRecordId: "",
        equipmentRecordName: "",
        equipmentRecordNo: "",
        equipmentDepartment: "",
        ctime: "",
        status: 1,
        approveStatus: 1,
        content: ""
      },
      formBackUp: {},
      passStatusData: [
        {
          label: "通过",
          value: 1
        },
        {
          label: "退回",
          value: 0
        }
      ],

      popupVisibleObj: {
        1: {
          visiable: false,
          title: "故障类型",
          value: "",
          list: []
        }
      },

      popupVisible: false,
      popupData: [],
      popupValue: ""
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      btnAuth: state => state.menu.btnAuth
    }),
    isAdd() {
      return this.$route.meta.type === "add";
    },
    faultStatus() {
      return this.form.status || 0;
    }
  },
  watch: {},
  created() {
    if (this.isAdd) {
      this.form.status = 0;
    }
    if (this.pageId) {
      this.getDeviceFaultInfo();
    }
  },
  methods: {
    getDeviceFaultInfo() {
      getDeviceFaultInfo(this.pageId).then(res => {
        res.equipmentRecordId = res.equipmentRecordId.join(",");
        res.equipmentRecordName = res.equipmentRecordName.join(",");
        res.equipmentRecordNo = res.equipmentRecordNo.join(",");
        res.approveStatus = 1;
        this.approveEditable = res.status === 1;
        this.form = res;
        this.form.equipmentRecordName = `${res.equipmentRecordName}(${res.equipmentRecordNo})`;
        this.popupValue = this.form.equipmentRecordId;
        this.formBackUp = JSON.parse(JSON.stringify(res));
      });
    },
    onPopupReset() {
      this.popupValue = "";
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      console.log(row);
      this.popupValue = row.id;
      this.form.equipmentRecordId = row.id;
      this.form.equipmentRecordName = row.name;
      this.form.equipmentRecordNo = row.machineNo;
      this.form.equipmentDepartment = row.department;
    },
    formatData() {
      return {
        description: this.form.description,
        type: this.form.type,
        equipmentRecordId: [this.form.equipmentRecordId],
        equipmentRecordName: [this.form.equipmentRecordName],
        equipmentRecordNo: [this.form.equipmentRecordNo],
        equipmentDepartment: this.form.equipmentDepartment
      };
    },
    async pageSave() {
      let data = this.formatData();
      await this.$refs.vanForm.validate();
      if (this.pageId) {
        data.id = this.pageId;
        updateSaveDeviceFault(data).then(res => {
          this.$router.push({ name: "DeviceFault" });
        });
      } else {
        saveDeviceFault(data).then(res => {
          this.$router.push({ name: "DeviceFault" });
        });
      }
    },
    async pageSubmit() {
      let data = this.formatData();
      await this.$refs.vanForm.validate();
      try {
        await operateDialog({
          title: "提示",
          message: "提交后不可编辑，确认提交？"
        });
        if (this.pageId) {
          data.id = this.pageId;
          updateSubmitDeviceFault(data).then(res => {
            this.$router.push({ name: "DeviceFault" });
          });
        } else {
          submitDeviceFault(data).then(res => {
            this.$router.push({ name: "DeviceFault" });
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async pageSignatureSubmit() {
      await this.$refs.vanForm.validate();
      let data = {
        id: this.pageId,
        content: this.form.content
      };
      try {
        await operateDialog({
          title: "提示",
          message: "确认提交？"
        });
        if (this.form.approveStatus) {
          confirmDeviceFault(data).then(res => {
            this.getDeviceFaultInfo();
          });
        } else {
          rejectDeviceFault(data).then(res => {
            this.getDeviceFaultInfo();
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async checkEquipment() {
      if (this.editable) {
        return false;
      }
      let params = {
        orgCode: this.userInfo.orgCode,
        scrap: 0
      };
      let res = await getLinkDeviceSltData(params).catch(_ => {});
      this.popupData =
        res.map(i => {
          let { name, ...rest } = i;
          return { ...rest, name: `${i.name}(${i.machineNo})` };
        }) || [];
      this.popupVisible = true;
    },
    goFlowPage() {
      this.$router.push({
        name: "DeviceFaultFlow",
        query: { faultId: this.pageId, name: this.$route.query.name }
      });
    },
    async select(prop) {
      if (this.editable) {
        return;
      }
      let data = [];
      data = await getDictDeviceFaultType();
      this.popupVisibleObj[prop].list = data;
      this.popupVisibleObj[prop].visiable = true;
    },
    popupGradeChange(v, filterListIdx, popupKey) {
      const vObj = v ? v[0] : {};
      this.popupVisibleObj[popupKey].value = vObj.value || "";
      this.form.typeName = vObj.label || "";
      this.form.type = vObj.value || "";
    },

    cancel() {
      this.editable = true;
      this.form = this.formBackUp;
      this.popupValue = this.form.equipmentRecordId;
    },
    async onClickLeft() {
      if (!this.editable || this.approveEditable) {
        try {
          await operateDialog({
            title: "提示",
            message: "数据尚未保存，确认返回？"
          });
          history.go(-1);
        } catch (e) {
          console.log(e);
        }
        return false;
      }
      history.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-point-add-wrapper {
  padding: 60px 0 20px 0;

  .right-btn {
    font-size: 16px;

    &:first-child {
      margin-right: 8px;
    }
  }

  .hide-error {
    ::v-deep {
      .van-field__error-message {
        display: none;
      }
    }
  }
}

.right-btn-icon {
  font-size: 24px;
}

.footer-box {
  position: absolute;
  bottom: 0;
  width: calc(100% - 32px);
  padding: 0 16px 16px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .confirm-btn {
    width: 110px;
    height: 44px;
    font-size: 18px;
    font-weight: 500;
  }

  .confirm-btn:last-child {
    margin: 0;
  }

  .confirm-btn.full,
  .confirm-btn:first-child:last-child {
    width: 100%;
    margin: 0;
  }
}
.is-add {
  .confirm-btn {
    width: 160px;
  }
}
.is-approve {
  .confirm-btn {
    width: 100%;
  }
}
</style>
